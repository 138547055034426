.App {
  margin: 0;
  overflow-y: hidden;
  overflow-x: "hidden";
  scrollbar-color: black black;
  height: 100%
}

.MobileApp{
  margin: 0;
    overflow-y: hidden;
    overflow-x: hidden;
}

.mainBG{
  height: 80vh;
  width: "100%";
  background-size: cover;
  position: relative;
}
.mainBGFaq {
  height: 80vh;
  width: "100%";
  background-size: cover;
  align-items: center;
  justify-content: center;
  position: relative;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected ::selection {
  background-color: #A74B74 !important;
  border-radius: 5px !important;
}

.ant-layout-header .ant-menu {
  line-height: initial !important;
  align-items: center;
}

// .ant-menu-item ::before {
//   // border-right: 1px solid #505050;
//   border-right: 1px solid #8c8f90;
//   height: 15px;
//   margin-top: 10px;
// }

.ant-menu>.ant-menu-item:hover,
.ant-menu>.ant-menu-submenu:hover,
.ant-menu>.ant-menu-item-active,
.ant-menu>.ant-menu-submenu-active,
.ant-menu>.ant-menu-item-open,
.ant-menu>.ant-menu-submenu-open,
.ant-menu>.ant-menu-item-selected,
.ant-menu>.ant-menu-submenu-selected ::selection{
  background-color: #A74B74 !important;
  border-radius: 5px !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// .ant-checkbox-checked .ant-checkbox-inner::before {
//   border-color: 1px solid red !important;
//   background-color: #61dafb;
// }

// .ant-checkbox .ant-checkbox-inner {
//   border-color: 1px solid red !important;
//   background-color: transparent !important;
// }


.mint-now{
    color: #fff;
    width: 80%;
    background-color: #5F2CA0;
    display: flex;
    text-align: center;
    justify-content: center; 
    align-items: center;
    border-radius: 13.01px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 24px;
    font-weight: 700;
    font-family: "Poppins";
    margin-top: 20px;
    margin-bottom: 15px;
    cursor: pointer;
&:hover {
  background-color:#A74B74;
}
}

.mint-now-mobile{color: #fff;
  width: 95%;
  background-color: #5F2CA0;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 13.01px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
  font-family: "Poppins";
  margin-top: 20px;
  margin-bottom: 15px;
  cursor: pointer;

  &:hover {
    background-color: #A74B74;
  }}


.ant-menu-dark.ant-menu-horizontal>.ant-menu-submenu{
  background-color: #A74B74;
  border-radius: 5px !important;
}